import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, of } from 'rxjs';
import { ChildEntityService } from '../../services/child-entity-service.service';
import { ProductService } from '../../services/product-service.service';
import {
  loadProductsEntities,
  loadProductsEntitiesSuccess,
  loadProductTypesFailure,
  loadProductTypesSuccess,
} from './products.action';
import {
  loadProductsFailure,
  loadProductsSuccess,
} from '../../customer/states/products/products.action';
import { PosSwalHelper } from '../../shared/pos-swal-helper';
import { ApiResponse } from '../../models/response.model';

@Injectable()
export class ProductsEffect {
  constructor(
    private actions$: Actions,
    private entityChildService: ChildEntityService,
    private productService: ProductService
  ) {}

  loadChildEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProductsEntities),
      switchMap(({ event }) =>
        this.entityChildService.getChildEntityByEntity(event).pipe(
          map((res: ApiResponse) => {
            if (res.isSuccess) {
              const entityChildList = res.content.map((option: any) => ({
                childId: option.systemId,
                childEntityName: option.outletDisplayName,
              }));
              return loadProductsSuccess({ products: entityChildList });
            } else {
              PosSwalHelper.forPosFailure(res.message);
              return loadProductsFailure({ message: res.message });
            }
          }),
          catchError((error) => of(loadProductsFailure({ message: error })))
        )
      )
    )
  );

  loadProductTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProductsEntitiesSuccess),
      switchMap(() =>
        this.productService.getProductTypes().pipe(
          map((res: any) => {
            if (res.length > 0) {
              const productTypes = res.map((element: any) => ({
                productTypeId: element.productTypeId,
                categoryDisplayName: element.categoryDisplayName,
              }));
              return loadProductTypesSuccess({ productTypes });
            } else {
              PosSwalHelper.forPosFailure('No data found!');
              return loadProductTypesSuccess({ productTypes: [] });
            }
          }),
          catchError((error) => of(loadProductTypesFailure({ error })))
        )
      )
    )
  );
}
