import { createReducer, on } from "@ngrx/store";
import { loadProductsEntitiesSuccess, loadProductTypesSuccess } from "./products.action";

export interface ProductsState {
  entityProductsList: any[];
  productTypes: any[];
  error: string | null;
}

export const initialState: ProductsState = {
  entityProductsList: [],
  productTypes: [],
  error: null,
};

export const productsReducer = createReducer(
  initialState,
  on(loadProductsEntitiesSuccess, (state, { entityProductsList }) => ({
    ...state,
    entityProductsList,
    error: null,
  })),
  on(loadProductTypesSuccess, (state, { productTypes }) => ({
    ...state,
    productTypes,
    error: null,
  }))
);
