import { createAction, props } from "@ngrx/store";

export const loadProductsEntities = createAction(
  '[Entity] Load Products Entities',
  props<{ event: any }>()
);

export const loadProductsEntitiesSuccess = createAction(
  '[Entity] Load Products Entities Success',
  props<{ entityProductsList: any[] }>()
);

export const loadProductsEntitiesFailure = createAction(
  '[Entity] Load Products Entities Failure',
  props<{ error: string }>()
);

// Fetch product types
export const loadProductTypes = createAction('[Product] Load Product Types');

export const loadProductTypesSuccess = createAction(
  '[Product] Load Product Types Success',
  props<{ productTypes: any[] }>()
);

export const loadProductTypesFailure = createAction(
  '[Product] Load Product Types Failure',
  props<{ error: string }>()
);
