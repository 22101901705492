import {  Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class AuthRedirectGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      map((authenticated) => !authenticated), // Reverse logic: allow only if NOT authenticated
      tap((canAccess) => {
        if (!canAccess) {
          this.router.navigate(['/dashboard/home']); // Redirect to the dashboard
        }
      })
    );
  }
}
